import { Container, Fade, Grid, Modal, Paper, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { PrimaryButton } from 'components/atoms/Buttons';
import { Headings, MediumText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import styles from 'styles/Home.module.css';
import becomePartner from 'assets/svg/becomePartner.svg';
import BecomePartnerPopUp from 'components/BecomePartnerPopUp';

interface IBecomePartnerProps {}

const BecomePartner = ({}: IBecomePartnerProps) => {
    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('md'));

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { t } = useTranslation('common');

    const handlePopUpOpen = () => {
        setModalOpen(true);
    };

    const handlePopUpClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Container maxWidth="md">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles['join-us-background-container']}
                    flexDirection={matched ? 'column' : 'row'}
                >
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }} mb={4}>
                        <Box>
                            <Headings.H2>{t('becomePartner')}</Headings.H2>
                        </Box>
                    </Box>
                    <Grid container spacing={10} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '50%', md: '90%' },
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={becomePartner}
                                        style={{
                                            width: '100%',
                                        }}
                                        width={{ xs: undefined, sm: '500' }}
                                        height={{ xs: undefined, sm: '500' }}
                                        alt="Become a partner"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <Box>
                                        <Headings.H2>{t('becomePartner')}</Headings.H2>
                                    </Box>
                                </Box>
                                <Box
                                    textAlign={{ xs: 'center', md: 'left' }}
                                    sx={{
                                        maxWidth: { xs: 'unset', sm: '500px' },
                                    }}
                                ></Box>
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }} mt={2}>
                                    <Box pt={1}>
                                        <PrimaryButton onClick={handlePopUpOpen}>
                                            <MediumText>{t('apply_now')}</MediumText>
                                        </PrimaryButton>
                                    </Box>
                                </Box>
                                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <Box width="100%" display="flex" justifyContent="center">
                                        <Box>
                                            <PrimaryButton onClick={handlePopUpOpen}>
                                                <MediumText>{t('apply_now')}</MediumText>
                                            </PrimaryButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                open={modalOpen}
                onClose={handlePopUpClose}
                closeAfterTransition
            >
                <Fade in={modalOpen}>
                    <Paper>
                        <BecomePartnerPopUp onClose={handlePopUpClose} />
                    </Paper>
                </Fade>
            </Modal>
        </>
    );
};

export default BecomePartner;
