import { Box, IconButton, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Headings, LargeText, MediumText, NormalText } from './atoms/Typography';
import blueLogo from 'assets/svg/TakeawayLogo.svg';
import suggestImage from 'assets/svg/suggestPopUp.svg';
import { BasicInput } from './atoms/Inputs';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ActionButton } from './atoms/Buttons';
import useTranslation from 'next-translate/useTranslation';
import upperCase from 'lodash/upperCase';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { _generateToken } from 'services/cart/useCartService/token';

interface ISuggestPopUpProps {
    onClose?: () => void;
}
interface SuggestRestaurantForm {
    restaurant: string;
    message: string;
}

const SuggestPopUp = ({ onClose }: ISuggestPopUpProps) => {
    const [suggestForm, setSuggestForm] = useState<SuggestRestaurantForm>(null);
    const theme = useTheme();
    const matchedSmall = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('common');

    const { enqueueSnackbar } = useSnackbar();

    const handleCloseModal = () => {
        if (onClose) onClose();
    };
    const handleInputChange = name => event => {
        setSuggestForm({ ...suggestForm, [name]: event.target.value });
    };

    const handleSuggestRestaurant = async () => {
        const token = await _generateToken();
        axios
            .post(
                `${process.env.NEXT_PUBLIC_API_ENDPOINT}/v1/email/recommendRestaurant`,
                {
                    title: suggestForm.restaurant,
                    recommendation: suggestForm.message,
                },
                {
                    headers: {
                        'x-access-token': token,
                    },
                }
            )
            .then(() => {
                enqueueSnackbar('Your suggestion is sent!', { variant: 'success' });
                onClose();
            })
            .catch(e => {
                enqueueSnackbar('Error while sending suggesting restaurant!', { variant: 'error' });

                onClose();
            });
    };

    return (
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            maxWidth="95vw"
            // minHeight="60vh"
            maxHeight="90vh"
            style={{ fontSize: '12px' }}
            pb={5}
        >
            <Box display="flex" justifyContent="space-between">
                <Box p={1}>
                    <img src={blueLogo} style={{ width: '150px' }} />
                </Box>
                <Box pr={1}>
                    <IconButton onClick={handleCloseModal} size="large">
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} minHeight={0} overflow="auto" px={matchedSmall ? 1 : 5}>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Box pt={6} pr={6} width={300}>
                        <img src={suggestImage} style={{ width: '100%' }} />
                    </Box>
                </Box>
                <Box pb={1} display="flex" flexDirection="column">
                    <Box pt={matchedSmall ? 1 : 0} style={{ fontSize: '10px' }}>
                        <Headings.H3 color="primary">{upperCase(t('recommendRestaurant'))}</Headings.H3>
                    </Box>
                    <Box pt={2}>
                        <MediumText>{t('whichRestaurant')}?</MediumText>
                        <BasicInput onInput={handleInputChange('restaurant')} />
                    </Box>
                    <Box pt={2} pb={2}>
                        <MediumText>{t('whyThisRestaurant')}?</MediumText>
                        <TextField fullWidth variant="outlined" rows={4} onInput={handleInputChange('message')} />
                    </Box>
                    <Box pt={2}>
                        <ActionButton fullWidth onClick={handleSuggestRestaurant}>
                            <LargeText> {t('submit')}</LargeText>
                        </ActionButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SuggestPopUp;
