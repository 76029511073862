import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Headings, LargeText, MediumText, NormalText, SmallText } from './atoms/Typography';
import blueLogo from 'assets/svg/TakeawayLogo.svg';
import joinImage from 'assets/svg/join-the-team-img.svg';
import { BasicInput } from './atoms/Inputs';
import React, { useState } from 'react';
import uploadImg from 'assets/svg/uploadImg.svg';
import { ActionButton } from './atoms/Buttons';
import Dropzone from 'react-dropzone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import Render from './atoms/Render';
import useTranslation from 'next-translate/useTranslation';
import upperCase from 'lodash/upperCase';
import axios from 'axios';
import { _generateToken } from 'services/cart/useCartService/token';
import { useSnackbar } from 'notistack';

interface IJoinUsPopUpProps {
    onClose?: () => void;
}
interface JoinUsForm {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    message: string;
}

const JoinUsPopUp = ({ onClose }: IJoinUsPopUpProps) => {
    const [role] = React.useState('');
    const [joinUsForm, setJoinUsForm] = useState<JoinUsForm>(null);
    const [preview, setPreview] = useState<any>(null);
    const theme = useTheme();
    const matchedSmall = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('common');
    const formData = new FormData();

    const { enqueueSnackbar } = useSnackbar();

    const handleCloseModal = () => {
        if (onClose) onClose();
    };

    const handleInputChange = name => event => {
        setJoinUsForm({ ...joinUsForm, [name]: event.target.value });
    };
    const handleDrop = preview => {
        setPreview(preview);
    };
    const deleteAttachment = () => {
        setPreview(null);
    };
    const handleSubmitJoinUsForm = async () => {
        if(checkForm()){
            const token = await _generateToken();
            formData.append('firstName', joinUsForm?.firstName);
            formData.append('lastName', joinUsForm?.lastName);
            formData.append('email', joinUsForm?.email);
            formData.append('phoneNumber', joinUsForm?.phone);
            formData.append('role', joinUsForm?.role);
            formData.append('file', preview?.[0]);
            formData.append('message', joinUsForm?.message);
    
            axios
                .post(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/v1/email/applyForJob`, formData, {
                    headers: {
                        'x-access-token': token,
                    },
                })
                .then(() => {
                    enqueueSnackbar('Your application is sent!', { variant: 'success' });
                    onClose();
                })
                .catch(e => {
                    enqueueSnackbar('Error while sending application!', { variant: 'error' });
                    console.log('error while sending application ', e.response);
                    onClose();
                });
        }else{
            enqueueSnackbar(t('require_fields'), { variant: 'error' });
        }
      
    };

    const checkForm = ()=>{
        if(joinUsForm?.firstName && joinUsForm?.lastName && joinUsForm?.email && joinUsForm?.phone && joinUsForm?.role && preview?.[0]){
            return true
        } else {
            return false
        }
    }

    return (
        <Box
            p={1}
            height="82vh"
            maxWidth="90vw"
            display="flex"
            flexDirection="column"
            overflow="auto"
            minHeight="0"
            style={{ fontSize: '14px' }}
        >
            <Box display="flex" justifyContent="space-between">
                <Box p={1}>
                    <img src={blueLogo} style={{ width: '150px' }} />
                </Box>
                <Box pr={1}>
                    <IconButton onClick={handleCloseModal} size="large">
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
            </Box>

            <Box display="flex" pr={5} flex={1} minHeight={0} overflow="auto">
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Box pt={4} width={300}>
                        <img src={joinImage} width="100%" />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" pl={2}>
                    <Box pb={2} display="flex" justifyContent="center">
                        <Headings.H3 color="primary">{upperCase(t('joinTheTeam'))}</Headings.H3>
                    </Box>
                    <MediumText>{t('personalInformation')}</MediumText>
                    <Box pt={1} display="flex" flexDirection={matchedSmall ? 'column' : 'row'}>
                        <Box pr={matchedSmall ? 0 : 1}>
                            <BasicInput placeholder={t('firstName')} onInput={handleInputChange('firstName')} />
                        </Box>
                        <Box mt={matchedSmall ? 2 : 0}>
                            <BasicInput placeholder={t('lastName')} onInput={handleInputChange('lastName')} />
                        </Box>
                    </Box>
                    <Box pt={2}>
                        <BasicInput placeholder={t('email')} onInput={handleInputChange('email')} />
                    </Box>
                    <Box pt={2} pb={3}>
                        <BasicInput placeholder={t('phone')} onInput={handleInputChange('phone')} />
                    </Box>
                    <MediumText>{t('chooseJobPosition')}</MediumText>
                    <Box pt={1} pb={2}>
                        <FormControl variant="outlined" sx={{ fontSize: '16px' }} fullWidth>
                            <InputLabel id="role-label-id" style={{ fontSize: '1em' }}>
                                {t('role')}
                            </InputLabel>
                            <Select
                                value={joinUsForm?.role || role}
                                onChange={handleInputChange('role')}
                                label={t('role')}
                                native={matchedSmall}
                                labelId="role-label-id"
                                style={{ fontSize: '16px' }}
                                id="demo-simple-select"
                            >
                                <MenuItem value="driver">{t('driver', { count: 1 })}</MenuItem>
                                <MenuItem value="dispatcher">{t('dispatcher', { count: 1 })}</MenuItem>
                                <MenuItem value="marketing">{t('marketing')}</MenuItem>
                                <Render when={matchedSmall}>
                                    <>
                                        <option value="driver">{t('driver', { count: 1 })}</option>
                                        <option value="dispatcher">{t('dispatcher', { count: 1 })}</option>
                                        <option value="marketing">{t('marketing')}</option>
                                    </>
                                </Render>
                            </Select>
                        </FormControl>
                    </Box>
                    <MediumText>{t('attachCV')}</MediumText>
                    <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {!preview && (
                                        <Box
                                            mt={1}
                                            mb={2}
                                            width="100%"
                                            height="140px"
                                            sx={{
                                                border: `2px dashed ${theme.palette.grey[900]}`,
                                                cursor: 'pointer',
                                            }}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box pb={1}>
                                                <Box
                                                    component="img"
                                                    src={uploadImg}
                                                    sx={{
                                                        width: '20px',
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                                                <MediumText>{t('dragAndDropHere')}</MediumText>
                                                <MediumText> {t('or')} </MediumText>
                                            </Box>
                                            <MediumText semibold color="secondary">
                                                {t('browseFiles')}
                                            </MediumText>
                                        </Box>
                                    )}
                                    {preview && (
                                        <Box pb={2} display="flex" justifyContent="space-between">
                                            <SmallText color="secondary">{preview[0].name}</SmallText>
                                            <DeleteForeverIcon
                                                color="error"
                                                onClick={deleteAttachment}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Box>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <Box>
                        <Box pb={1}>
                            <MediumText>{t('leaveAMessage')}</MediumText>
                        </Box>
                        <TextField fullWidth variant="outlined" onInput={handleInputChange('message')} />
                    </Box>
                </Box>
            </Box>
            <Box pt={2}>
                <ActionButton fullWidth onClick={handleSubmitJoinUsForm}>
                    <LargeText> {t('submit')}</LargeText>
                </ActionButton>
            </Box>
        </Box>
    );
};

export default JoinUsPopUp;
