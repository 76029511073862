import { Box, Container, Fade, Grid, Modal, Paper, useMediaQuery, useTheme } from '@mui/material';
import streetFoodImage from 'assets/svg/Street Food-amico.svg';
import { PrimaryButton } from 'components/atoms/Buttons';
import { Headings, MediumText, NormalText } from 'components/atoms/Typography';
import SuggestPopUp from 'components/SuggestPopUp';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

// const SuggestPopUp = loadable(() => import('components/SuggestPopUp'));

interface ISuggestRestaurantProps {}

const SuggestRestaurant = ({}: ISuggestRestaurantProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const theme = useTheme();
    const { t } = useTranslation('common');
    const matched = useMediaQuery(theme.breakpoints.down('md'));
    const handlePopUpOpen = () => {
        setModalOpen(true);
    };
    const handlePopUpClose = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Box>
                <Container maxWidth="md">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection={{ xs: 'column-reverse', md: 'row' }}
                        ml={1}
                    >
                        <Grid container spacing={4} alignItems="center" direction={{ xs: 'column-reverse', md: 'row' }}>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}
                                        maxWidth={{ xs: undefined, sm: '70%', md: 'unset' }}
                                    >
                                        <Box
                                            textAlign={{ xs: 'center', md: 'left' }}
                                            sx={{
                                                maxWidth: {
                                                    xs: 'unset',
                                                    md: '80%',
                                                    lg: '60%',
                                                },
                                            }}
                                        >
                                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                <Headings.H2>{t('favoriteRestaurantNotYet')}</Headings.H2>
                                            </Box>
                                        </Box>
                                        <Box
                                            py={2}
                                            textAlign={{ xs: 'center', md: 'left' }}
                                            sx={{
                                                maxWidth: {
                                                    xs: 'unset',
                                                    md: '500px',
                                                },
                                            }}
                                        >
                                            <MediumText style={{ fontSize: '18px' }}>{t('suggest_restaurant')}</MediumText>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            <Box pt={1}>
                                                <PrimaryButton onClick={handlePopUpOpen}>
                                                    <MediumText>{t('suggestRestaurant')}</MediumText>
                                                </PrimaryButton>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                            <Box width="100%" display="flex" justifyContent="center">
                                                <Box pt={1}>
                                                    <PrimaryButton onClick={handlePopUpOpen}>
                                                        <MediumText>{t('suggestRestaurant')}</MediumText>
                                                    </PrimaryButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                                >
                                    <Box sx={{ width: { xs: '90%', sm: '90%', md: '100%' } }}>
                                        <Box
                                            component="img"
                                            src={streetFoodImage}
                                            style={{ width: '100%' }}
                                            width={{ xs: undefined, sm: '500' }}
                                            height={{ xs: undefined, sm: '500' }}
                                            alt="suggest a restaurant"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <Box display="flex" justifyContent="center">
                                <Headings.H2 style={{ textAlign: 'center' }}>{t('favoriteRestaurantNotYet')}</Headings.H2>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={modalOpen}
                    onClose={handlePopUpClose}
                    closeAfterTransition
                >
                    <Fade in={modalOpen}>
                        <Paper>
                            <SuggestPopUp onClose={handlePopUpClose} />
                        </Paper>
                    </Fade>
                </Modal>
            </Box>
        </>
    );
};

export default SuggestRestaurant;
