import { IconButton, TextField, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import { ActionButton } from './atoms/Buttons';
import { BasicInput } from './atoms/Inputs';
import { Headings, LargeText, MediumText, NormalText } from './atoms/Typography';
import CloseIcon from '@mui/icons-material/Close';
import blueLogo from 'assets/svg/TakeawayLogo.svg';
import { upperCase } from 'lodash';
import becomePartnerPopUp from 'assets/svg/becomePartnerPopUp.svg';
import axios from 'axios';
import { _generateToken } from 'services/cart/useCartService/token';
import { useSnackbar } from 'notistack';
import { useValidator } from 'services/validation/validator';
import ValidationMessage from './atoms/ValidationMessage';

interface IBecomePartnerPopUpProps {
    onClose?: () => void;
}

interface BecomePartnerForm {
    title: string;
    email: string;
    phoneNumber: string;
    overview: string;
}

const BecomePartnerPopUp = ({ onClose }: IBecomePartnerPopUpProps) => {
    const [suggestForm, setSuggestForm] = useState<BecomePartnerForm>(null);

    const theme = useTheme();
    const matchedSmall = useMediaQuery(theme.breakpoints.down('md'));

    const validator = useValidator();

    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();

    const handleCloseModal = () => {
        if (onClose) onClose();
    };

    const handleInputChange = name => event => {
        setSuggestForm({ ...suggestForm, [name]: event.target.value });
    };

    const handleBecomePartner = async () => {
        const valid = await validator.validate('BECOME_PARTNER', {
            email: suggestForm?.email,
            phone: suggestForm?.phoneNumber,
        });

        if (valid) {
            const token = await _generateToken();

            axios
                .post(
                    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/v1/email/becomePartner`,
                    {
                        title: suggestForm?.title,
                        email: suggestForm?.email,
                        phoneNumber: suggestForm?.phoneNumber,
                        overview: suggestForm?.overview,
                    },
                    {
                        headers: {
                            'x-access-token': token,
                        },
                    }
                )
                .then(() => {
                    enqueueSnackbar('Your application is sent!', { variant: 'success' });
                    onClose();
                })
                .catch(e => {
                    enqueueSnackbar(`Error while sending application for partner! Error ${e.message}`, { variant: 'error' });
                    console.log('error while sending application ', e.response);
                });
        }
    };

    return (
        <Box p={1} display="flex" flexDirection="column" maxWidth="95vw" maxHeight="90vh" style={{ fontSize: '12px' }} pb={5}>
            <Box display="flex" justifyContent="space-between">
                <Box p={1}>
                    <img src={blueLogo} style={{ width: '150px' }} />
                </Box>
                <Box pr={1}>
                    <IconButton onClick={handleCloseModal} size="large">
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" flex={1} minHeight={0} overflow="auto" px={matchedSmall ? 1 : 5}>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Box pt={6} pr={6} width={300}>
                        <img src={becomePartnerPopUp} style={{ width: '100%' }} />
                    </Box>
                </Box>
                <Box pb={1} display="flex" flexDirection="column">
                    <Box pt={matchedSmall ? 1 : 0} style={{ fontSize: '10px' }}>
                        <Headings.H3 color="primary">{upperCase(t('becomePartner'))}</Headings.H3>
                    </Box>
                    <Box pt={2}>
                        <MediumText>{t('whichRestaurant')}?</MediumText>
                        <BasicInput onInput={handleInputChange('title')} />
                    </Box>
                    <Box pt={2}>
                        <MediumText>{t('restaurantEmail')}?</MediumText>
                        <BasicInput onInput={handleInputChange('email')} error={validator.hasError('BECOME_PARTNER', 'email')} />
                        {validator.hasError('BECOME_PARTNER', 'email') && <ValidationMessage type="BECOME_PARTNER" />}
                    </Box>
                    <Box pt={2}>
                        <MediumText>{t('contactNumber')}?</MediumText>
                        <BasicInput
                            onInput={handleInputChange('phoneNumber')}
                            error={validator.hasError('BECOME_PARTNER', 'phone')}
                        />
                        {validator.hasError('BECOME_PARTNER', 'phone') && <ValidationMessage type="BECOME_PARTNER" />}
                    </Box>
                    <Box pt={2} pb={2}>
                        <MediumText>{t('whyThisRestaurant')}?</MediumText>
                        <TextField fullWidth variant="outlined" rows={4} onInput={handleInputChange('overview')} />
                    </Box>
                    <Box pt={2}>
                        <ActionButton fullWidth onClick={handleBecomePartner}>
                            <LargeText> {t('submit')}</LargeText>
                        </ActionButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BecomePartnerPopUp;
