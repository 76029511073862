import { Box, Container, Fade, Grid, Modal, Paper, useMediaQuery, useTheme } from '@mui/material';
import { PrimaryButton } from 'components/atoms/Buttons';
import { Headings, LargeText, MediumText, NormalText } from 'components/atoms/Typography';
import styles from 'styles/Home.module.css';
import takeAwayImg from 'assets/svg/take-away-amico.svg';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import JoinUsPopUp from 'components/JoinUsPopUp';
import takeAwayJoinUs from 'assets/svg/takeAwayJoinUs.svg';

// const JoinUsPopUp = loadable(() => import('components/JoinUsPopUp'));

const JoinUs = () => {
    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('md'));
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { t } = useTranslation('common');
    const handlePopUpOpen = () => {
        setModalOpen(true);
    };
    const handlePopUpClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Box
                className={styles['join-us-background-container']}
                sx={{
                    backgroundColor: theme.palette.grey[50],
                    // height: '400px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Container maxWidth="md">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection={matched ? 'column' : 'row'}
                        // px={{ xs: 2, sm: 5, md: 10, lg: 20 }}
                        pb={4}
                    >
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <Box mt={12} textAlign="center">
                                <Headings.H2>{t('joinTheTeam')}</Headings.H2>
                            </Box>
                        </Box>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
                                >
                                    <Box
                                        sx={{
                                            width: { xs: '90%', sm: '60%', md: 'unset' },
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={takeAwayJoinUs}
                                            style={{
                                                width: '100%',
                                            }}
                                            width={{ xs: undefined, sm: '500' }}
                                            height={{ xs: undefined, sm: '500' }}
                                            alt="join us"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" justifyContent="center">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}
                                        maxWidth={{ xs: undefined, sm: '70%', md: 'unset' }}
                                    >
                                        <Box sx={{ display: { xs: 'none', sm: 'block' } }} mb={2}>
                                            <Box>
                                                <Headings.H2>{t('joinUs')}</Headings.H2>
                                            </Box>
                                        </Box>
                                        <Box
                                            textAlign={{ xs: 'center', md: 'left' }}
                                            sx={{
                                                maxWidth: { xs: 'unset', md: '500px' },
                                            }}
                                        >
                                            <MediumText>{t('joinUsDesc')}</MediumText>
                                        </Box>
                                        <Box
                                            pb={2}
                                            textAlign={{ xs: 'center', md: 'left' }}
                                            sx={{
                                                maxWidth: { xs: 'unset', md: '500px' },
                                            }}
                                        >
                                            <MediumText>{t('applyAndJoin')}</MediumText>
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            <Box pt={1}>
                                                <PrimaryButton onClick={handlePopUpOpen}>
                                                    <MediumText> {t('apply_now')}</MediumText>
                                                </PrimaryButton>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                            <Box width="100%" display="flex" justifyContent="center">
                                                <Box pt={1}>
                                                    <PrimaryButton onClick={handlePopUpOpen}>
                                                        <MediumText>{t('apply_now')}</MediumText>
                                                    </PrimaryButton>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={modalOpen}
                    onClose={handlePopUpClose}
                    closeAfterTransition
                >
                    <Fade in={modalOpen}>
                        <Paper>
                            <JoinUsPopUp onClose={handlePopUpClose} />
                        </Paper>
                    </Fade>
                </Modal>
            </Box>
        </>
    );
};

export default JoinUs;
