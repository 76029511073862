import { East, West } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Headings, LargeText, MediumText, NormalText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import CircleArrow from './CircleArrow';

interface ICarouselProps {
    children?: ReactNode;
    slidesToShow?: number;
    responsive?: ResponsiveObject[];
    arrows?: boolean;
    variableWidth?: boolean;
    title?: string;
    count?: number;
    boldTitle?: boolean;
    onSeeAll?: () => void;
    afterChange?: () => void;
    beforeChange?: () => void;
    onSwipe?: () => void;
}

const SectionCarousel = ({
    children,
    slidesToShow,
    responsive,
    variableWidth,
    title,
    count,
    boldTitle,
    onSeeAll,
    afterChange,
    beforeChange,
    onSwipe,
}: ICarouselProps) => {
    const ref = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const { t } = useTranslation('common');
    const [swiped, setSwiped] = useState<boolean>(false);

    const settings = {
        dots: false,
        row: 1,
        slidesToShow: slidesToShow,
        infinite: false,
        // centerPadding: '80px',
        responsive,
        arrows: false,
        variableWidth,
        // slidesToScroll: 1,
        swipeToSlide: true,
        fade: false,
    };

    const disabledArrows = slidesToShow >= count;

    const handleSwipe = useCallback(() => {
        // onSwipe?.();
    }, []);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Headings.H3 fontWeight={boldTitle ? 'bold' : 'normal'}>{title}</Headings.H3>
                <Box display="flex" gap={2} alignItems="center">
                    {onSeeAll && (
                        <Box>
                            <Button onClick={onSeeAll}>
                                <NormalText color="#009DE0" fontWeight="bold">
                                    {t('seeAll')}
                                </NormalText>
                            </Button>
                        </Box>
                    )}
                    <CircleArrow
                        left
                        onClick={() => {
                            ref.current?.slickPrev();
                        }}
                        disabled={disabledArrows || currentSlide === 0}
                    />
                    <CircleArrow
                        right
                        onClick={() => {
                            ref.current?.slickNext();
                        }}
                        disabled={disabledArrows || currentSlide === count - slidesToShow}
                    />
                </Box>
            </Box>
            <Box
                mt={2}
                sx={{
                    ml: '-10px',
                }}
            >
                <Slider
                    {...settings}
                    // key={slidesToShow}
                    ref={ref}
                    afterChange={currentSlide => {
                        setCurrentSlide(currentSlide);
                        afterChange?.();
                    }}
                    // beforeChange={beforeChange}
                    onSwipe={handleSwipe}
                >
                    {children}
                </Slider>
            </Box>
        </Box>
    );
};

export default SectionCarousel;
