import { Box, Button, Container, TextField } from '@mui/material';
import { Headings, MediumText, NormalText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';
import heroSectionImage from 'assets/svg/hero-section.svg';
import NewSearch from 'components/NewSearch';
import heroSectionBallon from 'assets/svg/hero-section-ballon.svg';
import { useRouting } from 'services/routing';
import { useCurrentLocationContext } from 'context/location';
import { useEffect, useState } from 'react';

import LocationSearch from 'components/LocationSearch';
import GoogleMapsApiContainer from 'containers/google-maps/GoogleMapsApiContainer';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import * as foodJson from 'assets/lottie/food-delivery.json';
import Lottie from 'react-lottie';
import styles from 'styles/search.module.css';
import locationVector from 'assets/svg/locationVector.svg';
import { CustomerAddress, Region } from 'klikni-jadi-shared-stuff';
import { collection } from 'services/db/firestoreApi';
import { NavigateNext } from '@mui/icons-material';
import { useLocationContext } from 'context/current-location';
import { entries } from 'lodash';

interface ILandingPageTopSectionProps {
    regions: Region[];
}

const LandingPageTopSection = ({ regions = [] }: ILandingPageTopSectionProps) => {
    const { t } = useTranslation('common');
    const { update } = useLocationContext();
    const { isLoaded, loadError } = useSelector((state: RootState) => state.mapInfo.data);
    const routing = useRouting();
    const user = useSelector((state: RootState) => state.currentUser);

    const handleRegionClick = (region: Region) => {
        if (user?.data) {
            if (user?.data?.addresses) {
                const _addresses = entries(user.data.addresses);
                const regionAddresses = _addresses.filter(([, a]) => a.region === region.id);
                let userAddress: CustomerAddress = null;
                let id: string = null;
                if (regionAddresses.length > 0) {
                    const primary = regionAddresses.find(([, a]) => a.isDefault);
                    if (primary) {
                        userAddress = primary[1];
                        id = primary[0];
                    } else {
                        userAddress = regionAddresses[0][1];
                        id = regionAddresses[0][0];
                    }
                }

                if (userAddress) {
                    update('__data__', {
                        location: {
                            latitude: userAddress.location.latitude,
                            longitude: userAddress.location.longitude,
                            fullAddress: userAddress.fullAddress,
                        },
                        region: region.id,
                        locationType: 'address',
                    });
                    routing.push({
                        to: '/partners/discover',
                        queries: {
                            nearby: `${[userAddress.location?.latitude, userAddress.location?.longitude].join(',')}`,
                            city: region.id,
                        },
                    });
                } else {
                    update('__data__', {
                        location: {
                            latitude: region.location?.position.latitude,
                            longitude: region.location.position.longitude,
                            fullAddress: region.title,
                        },
                        region: region.id,
                        addressId: null,
                        locationType: 'city',
                    });
                    routing.push({
                        to: '/partners/discover',
                        queries: {
                            nearby: `${[region.location?.position?.latitude, region.location?.position?.longitude].join(',')}`,
                            city: region.id,
                        },
                    });
                }
            }
        } else {
            update('__data__', {
                location: {
                    latitude: region.location?.position.latitude,
                    longitude: region.location.position.longitude,
                    fullAddress: region.title,
                },
                region: region.id,
                addressId: null,
                locationType: 'city',
            });
            routing.push({
                to: '/partners/discover',
                queries: {
                    nearby: `${[region.location?.position?.latitude, region.location?.position?.longitude].join(',')}`,
                    city: region.id,
                },
            });
        }
    };

    return (
        <Box position="relative" height="40rem">
            <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                sx={{
                    backgroundColor: 'rgb(239, 236, 244)',
                    overflow: 'hidden',
                    // backgroundImage: 'url("/cover.jpeg")',
                    // backgroundSize: 'auto 40rem',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'right',
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <img
                        src="/cover.jpeg"
                        style={{
                            position: 'absolute',
                            top: 0,
                            height: '40rem',
                            left: '30%',
                        }}
                    />
                </Container>
            </Box>
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                <Container
                    maxWidth="md"
                    sx={{
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        position="absolute"
                        sx={{
                            bottom: { xs: 120, md: 160 },
                            left: 30,
                        }}
                    >
                        <Headings.H1
                            sx={{
                                lineHeight: '103.2%',
                                color: 'black',
                                width: { xs: '300px', sm: '400px', md: '500px' },
                                fontSize: { xs: '3.8em', md: '3.8em' },
                                fontWeight: 'normal',
                            }}
                        >
                            <span>
                                <b>{t('orderOnline')}</b>
                            </span>
                        </Headings.H1>

                        {/* <NewSearch isHeroSection={true} onSearch={handleSearch} /> */}
                        <GoogleMapsApiContainer>
                            {isLoaded && <LocationSearch />}
                            {!isLoaded && (
                                <Box mt={5}>
                                    <Box>
                                        <MediumText fontWeight="bold">{t('deliveryAddress')}</MediumText>
                                    </Box>
                                    <Box mt={1.5}>
                                        <Box
                                            display="flex"
                                            sx={{
                                                width: { xs: '300px', sm: '300px', md: '350px' },
                                                backgroundColor: 'white',
                                                border: `2px solid rgba(32, 33, 37, 0.12)`,
                                                p: 1.5,
                                                borderRadius: '0.5rem',
                                                '&:has(input:focus)': {
                                                    borderColor: 'primary.main',
                                                    borderStyle: 'solid',
                                                    borderWidth: 2,
                                                },
                                            }}
                                            flexDirection="row"
                                            alignItems="center"
                                        >
                                            <img src={locationVector} style={{ width: 20 }} />
                                            <Box flexGrow={1} ml={1} alignItems="center">
                                                <TextField
                                                    className={styles['klik-search-input']}
                                                    placeholder={t('choose_delivery_address')}
                                                    size="small"
                                                    sx={{
                                                        padding: 0,
                                                        border: 'none',
                                                        fontSize: { xs: '16px', md: '1em' },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            padding: 0,
                                                            minWidth: 0,
                                                        },
                                                        sx: {
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderStyle: 'none',
                                                            },
                                                        },
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            padding: 0,
                                                        },
                                                    }}
                                                    fullWidth
                                                    disabled
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </GoogleMapsApiContainer>
                        {regions.length > 0 && (
                            <>
                                <Box mt={3}>
                                    <MediumText fontWeight="bold">Или избери град</MediumText>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1} mt={1.3}>
                                    {regions.map(region => (
                                        <Button
                                            key={region.id}
                                            sx={{
                                                border: '2px solid #e0e0e0',
                                                backgroundColor: 'white',
                                                height: 50,
                                                pl: 2,
                                                minWidth: 150,
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5',
                                                },
                                            }}
                                            onClick={() => handleRegionClick(region)}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                                width="100%"
                                                justifyContent="space-between"
                                                color="black"
                                            >
                                                <MediumText>{region.title}</MediumText>
                                                <NavigateNext
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </Box>
                                        </Button>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default LandingPageTopSection;
