import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ReactNode } from 'react';

interface IPropertyCardSkeletonProps {
    children?: ReactNode;
}

const PropertyCardSkeleton = ({ children }: IPropertyCardSkeletonProps) => {
    if (children) return <Skeleton>{children}</Skeleton>;

    return (
        <Box>
            <Skeleton variant="rectangular" height={220} width={220} style={{ borderRadius: 10 }} />
            <Box mt={1}>
                <Skeleton width={220} />
            </Box>
        </Box>
    );
};

export default PropertyCardSkeleton;
