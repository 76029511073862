import { ActionStatus } from 'core/design/ActionStatus';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { buildActionType } from 'redux/actions/service/buildActionType';
import { VALIDATION_INFO_HANDLE } from 'redux/constants/validation';
import { RootState } from 'redux/reducers';
import { PayloadAction } from 'redux/types/actionTypes';
import { ValidationErrorObject, ValidationInfo } from 'redux/types/internalTypes';
import { ValidationType, VALIDATION_ERROR_OBJECT_DEFAULT } from 'types/validation';

export const setValidationInfo =
    (
        info: ValidationErrorObject,
        type: ValidationType
    ): ThunkAction<{}, RootState, {}, PayloadAction<ValidationInfo>> =>
    async (dispatch, getState) => {
        const validationInfo = getState().validationInfo.data;
        const newInfo = { ...validationInfo, [type]: info };

        dispatch({
            type: buildActionType(VALIDATION_INFO_HANDLE, ActionStatus.DONE),
            payload: newInfo,
        });
    };

export const resetValidationInfo =
    (...types: ValidationType[]): ThunkAction<{}, RootState, {}, PayloadAction<ValidationInfo>> =>
    async (dispatch, getState) => {
        const validationInfo = getState().validationInfo.data;

        let newInfo = { ...validationInfo };
        for (let i = 0; i < types.length; i++) {
            const element = types[i];
            newInfo = { ...newInfo, [element]: VALIDATION_ERROR_OBJECT_DEFAULT };
        }

        dispatch({
            type: buildActionType(VALIDATION_INFO_HANDLE, ActionStatus.DONE),
            payload: newInfo,
        });
    };

export const resetAllValidationInfo =
    (): ThunkAction<{}, RootState, {}, PayloadAction<ValidationInfo>> => async dispatch => {
        dispatch({ type: buildActionType(VALIDATION_INFO_HANDLE, ActionStatus.RESET) });
    };
