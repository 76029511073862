import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { GeocoderService } from 'services/mapService';
import useTranslation from 'next-translate/useTranslation';
import { PrimaryButton } from './atoms/Buttons';
import { LargeText, MediumText, NormalText } from './atoms/Typography';
import { isEqual } from 'lodash';
import { useRouting } from 'services/routing';
import { collection, doc } from 'services/db/firestoreApi';
import { Region } from 'klikni-jadi-shared-stuff';
import LandingSearch from './LandingSearch';
import { useLocationContext } from 'context/current-location';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import NewLandingSearch from './NewLandingSearch';
import { findRegionByLocation } from 'services/location/findRegionByLocation';
import { geocodeLocation } from 'services/location/geocodeLocation';

interface ILocationSearchProps {}

interface Address {
    fullAddress: string;
    latitude: number;
    longitude: number;
}
const LocationSearch = ({}: ILocationSearchProps) => {
    const [currentAddress, setCurrentAddress] = useState<Address>(null);
    const [selectedAddress, setSelectedAddress] = useState<Address>(null);
    const geocoderService = new GeocoderService();
    const { t } = useTranslation('common');
    const [currentRegion, setCurrentRegion] = useState<string>(null);
    const routing = useRouting();
    const [locationDenied, setLocationDenied] = useState<boolean>(false);
    const { update } = useLocationContext();
    const user = useSelector((state: RootState) => state.currentUser);

    useEffect(() => {
        const successCallback = position => {
            const { latitude, longitude } = position.coords;

            geocodeLocation(latitude, longitude).then(({ city, currentAddress }) => {
                setLocationDenied(false);
                setCurrentAddress(currentAddress);
                setCurrentRegion(city);
            });
        };
        const errorCallback = e => {
            console.log(e);
            setLocationDenied(true);
        };
        if (!user.data) {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                timeout: 5000,
                enableHighAccuracy: true,
            });
        }
    }, [user?.data]);

    const handleAddressChangeAutocomplete = async (t: {
        address: {
            fullAddress: string;
            latitude: number;
            longitude: number;
        };
    }) => {
        const { address } = t;

        if (address) {
            setSelectedAddress({ latitude: address.latitude, longitude: address.longitude, fullAddress: address.fullAddress });

            const region = await findRegionByLocation(address?.latitude, address?.longitude);

            if (
                address?.longitude === region.location?.position?.longitude &&
                address?.latitude === region.location?.position?.latitude
            ) {
                update('__data__', {
                    location: {
                        latitude: address?.latitude,
                        longitude: address.longitude,
                        fullAddress: region.title,
                    },
                    region: region?.id || null,
                    addressId: null,
                    locationType: 'city',
                });
                routing.push({
                    to: '/partners/discover',
                    queries: {
                        ...(address?.latitude
                            ? {
                                  nearby: `${[address?.latitude, address?.longitude].join(',')}`,
                                  city: region?.id,
                              }
                            : {}),
                    },
                });
            } else {
                update('__data__', {
                    location: {
                        latitude: address?.latitude,
                        longitude: address.longitude,
                        fullAddress: address.fullAddress,
                    },
                    region: region?.id || null,
                    addressId: null,
                    locationType: 'address',
                });
                routing.push({
                    to: '/partners/discover',
                    queries: {
                        ...(address?.latitude
                            ? {
                                  nearby: `${[address?.latitude, address?.longitude].join(',')}`,
                                  city: region?.id,
                              }
                            : {}),
                    },
                });
            }
        }
    };

    const getCurrentLocation = (latitude, longitude, fullAddress, city) => {
        setCurrentAddress({ latitude, longitude, fullAddress });

        setCurrentRegion(city);
        setLocationDenied(false);
    };

    return (
        <Box mt={5}>
            <Box>
                <MediumText fontWeight="bold">{t('deliveryAddress')}</MediumText>
            </Box>
            <Box mt={1.5} display="flex">
                {/* <LandingSearch
                    address={{
                        fullAddress: selectedAddress?.fullAddress,
                        longitude: selectedAddress?.longitude,
                        latitude: selectedAddress?.latitude,
                    }}
                    onAddressChange={address => handleAddressChangeAutocomplete({ address })}
                    placeholder={t('choose_delivery_address')}
                    onSetCurrentLocation={() => {
                        handleAddressChangeAutocomplete({ address: currentAddress });
                    }}
                    onGetCurrentLocation={getCurrentLocation}
                    locationDenied={locationDenied}
                /> */}
                <NewLandingSearch
                    address={{
                        fullAddress: selectedAddress?.fullAddress,
                        longitude: selectedAddress?.longitude,
                        latitude: selectedAddress?.latitude,
                    }}
                    onAddressChange={address => handleAddressChangeAutocomplete({ address })}
                    placeholder={t('choose_delivery_address')}
                    onSetCurrentLocation={() => {
                        handleAddressChangeAutocomplete({ address: currentAddress });
                    }}
                    onGetCurrentLocation={getCurrentLocation}
                    locationDenied={locationDenied}
                />
                {/* <Box py="2px" display="flex">
                    <PrimaryButton
                        sx={{ ml: 2, py: 1, px: 2, display: { xs: 'none', sm: 'block', border: '3px solid transparent' } }}
                    >
                        <NormalText fontWeight="bold" fontSize="1rem">
                            {t('search')}
                        </NormalText>
                    </PrimaryButton>
                </Box> */}
            </Box>
        </Box>
    );
};

export default LocationSearch;
