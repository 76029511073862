import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Headings } from 'components/atoms/Typography';
import { Order } from 'klikni-jadi-shared-stuff';
import React, { useEffect, useRef } from 'react';
import vector from 'assets/svg/view-more.svg';
import Link from 'next/link';
import { SmallText } from 'components/atoms/Typography';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { isClientSide } from 'services/utils';
import useTranslation from 'next-translate/useTranslation';
import LastOrderCard from './LastOrderCard';
import Carousel from 'components/specific/Carousel';
import Slider from 'react-slick';
import { PrimaryButton } from 'components/atoms/Buttons';
import SectionCarousel from 'containers/partners/components/SectionCarousel';

// const LastOrderCard = loadable(() => import('./LastOrderCard'));

interface LastOrdersProps {
    lastOrders?: Order[];
    allLastOrdersLen?: number;
}

const LastOrders = ({ lastOrders, allLastOrdersLen }: LastOrdersProps) => {
    const { observer, width } = useResizeObserver();
    const { t } = useTranslation('common');
    const slider = useRef<Slider>(null);
    useEffect(() => {
        if (isClientSide()) {
            const element = document.getElementById('carousel-container');
            if (element) {
                observer.observe(element);
                return () => {
                    observer.unobserve(element);
                };
            }
        }
    }, []);
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
    }

    return (
        <Box width="100%">
            {/* <PrimaryButton onClick={() => slider?.current?.slickNext()}>next</PrimaryButton> */}
            <Container maxWidth="md">
                <Box id="carousel-container" display="flex" paddingLeft="10px">
                    <Box width="100%">
                        <Box width="100%">
                            <SectionCarousel slidesToShow={width / 440} title={t('order_again')} boldTitle>
                                {lastOrders &&
                                    lastOrders.map(order => (
                                        <Box key={order.id}>
                                            <Box
                                                className="cursor-pointer"
                                                sx={{
                                                    width: '400px',
                                                    // height: '222px',
                                                }}
                                                ml={1}
                                                // mb="42px"
                                            >
                                                <LastOrderCard
                                                    key={order.orderCode?.short}
                                                    date={order.createdAt}
                                                    orderPrice={order.total}
                                                    orderNo={order.orderCode?.short}
                                                    status={order.status}
                                                    items={order.items}
                                                    restaurant={order.partner}
                                                    order={order}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                            </SectionCarousel>
                        </Box>
                    </Box>
                    {/* <Box sx={{ display: { xs: 'block', md: 'none' }, overflow: 'auto' }}>
                        <Box width="100%">
                            <Box width="100%" display="flex">
    
                                {lastOrders &&
                                    lastOrders.map(order => (
                                        <Box width="100%" key={order.id}>
                                            <Box
                                                className="cursor-pointer"
                                                mb="42px"
                                                style={{ maxHeight: '222px' }}
                                                mr={5}
                                                sx={{
                                                    width: '220px',
                                                    maxHeight: '222px',
                                                }}
                                            >
                                                <LastOrderCard
                                                    key={order.orderCode?.short}
                                                    date={order.createdAt}
                                                    orderPrice={order.total}
                                                    orderNo={order.orderCode?.short}
                                                    status={order.status}
                                                    items={order.items}
                                                    restaurant={order.restaurant}
                                                    order={order}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
    
                            </Box>
                        </Box>
                    </Box> */}
                </Box>
                {allLastOrdersLen > lastOrders?.length && (
                    <Box textAlign="right" pt={7}>
                        <Link href={{ pathname: '/profile/orders', query: { 'orders-view': 'history' } }}>
                            <a>
                                <Box pr={9}>
                                    <SmallText color="textPrimary">
                                        {t('seeMore')}{' '}
                                        <img src={vector} alt="vector" style={{ width: '20px', marginLeft: '5px' }}></img>
                                    </SmallText>
                                </Box>
                            </a>
                        </Link>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    orderCard: {
        width: '220px',
    },
}));

export default LastOrders;
