import * as yup from 'yup';
import { ValidationType } from '../../types/validation';
import { AnyObjectSchema } from 'yup';
import { string } from 'yup/lib/locale';

yup.addMethod(yup.string, 'min', function (min, msg) {
    return this.test({
        name: 'min',
        exclusive: true,
        message: `Password must be at least ${min} characters.`,
        test: value => !value || value.length >= min,
    });
});

const loginSchemaEmail = yup.object({
    email: yup.string().required().email(),
});

const loginSchemaEmailPassword = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

const registerSchema = yup.object({
    name: yup.string().required('Please insert name...'),
    email: yup.string().optional().email(),
    lastName: yup.string().required('Please insert last name...'),
    phone: yup.string().required(),
});

const registerPasswordSchema = yup.object({
    email: yup.string().required().email('Please insert email...'),
    password: yup.string().required().min(8),
    name: yup.string().required('Please insert name...'),
    lastName: yup.string().required('Please insert last name...'),
    phone: yup.string().required(),
});

const changePassword = yup.object({
    password: yup.string().required().min(8),
});

const becomePartner = yup.object({
    email: yup.string().required(),
    phone: yup.string().required(),
});

export const schemaType: Partial<Record<ValidationType, AnyObjectSchema>> = {
    EMAIL_LOGIN: loginSchemaEmail,
    EMAIL_PASSWORD_LOGIN: loginSchemaEmailPassword,
    REGISTER_INFO: registerSchema,
    REGISTER_INFO_EMAIL_PASSWORD: registerPasswordSchema,
    CHANGE_PASSWORD: changePassword,
    BECOME_PARTNER: becomePartner,
};
