import React, { useState } from 'react';
import { Box, Divider, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LargeText, NormalText, SmallText } from 'components/atoms/Typography';
import moment from 'moment';
import { CartItems, Order } from 'klikni-jadi-shared-stuff';
import { Timestamp } from '@firebase/firestore-types';
import { OrderStatus } from 'klikni-jadi-shared-stuff';
import { Restaurant } from 'klikni-jadi-shared-stuff';
import toPairs from 'lodash/toPairs';
import { useDataTranslator } from 'hooks/useDataTranslator';
import useTranslation from 'next-translate/useTranslation';
import loadable from '@loadable/component';
import { getOrderPrices } from 'services/utils';
import ModalPopup from 'components/molecules/ModalPopup';
import LastOrderDetails from '../LastOrderDetails';
import locationVector from 'assets/svg/pin.svg';

// const LastOrderDetails = loadable(() => import('../LastOrderDetails'));
// const ModalPopup = loadable(() => import('components/molecules/ModalPopup'));

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        border: `1px solid ${theme.palette.grey.A100}`,
        boxShadow: '0px 1.55032px 15.5032px rgba(85, 141, 202, 0.15)',
        paddingLeft: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                boxShadow: '0px 20px 40px rgba(85, 141, 202, 0.25)',
                transform: 'scale(1.05)',
            },
        },
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
        }),
        backgroundColor: 'white',
    },
    name: {
        lineHeight: '18px',
    },
    takeOut: {
        lineHeight: '12px',
    },
}));

interface ILastOrderCardProps {
    date: Timestamp;
    orderNo: string;
    status: OrderStatus;
    items: CartItems;
    orderPrice: number;
    restaurant: Partial<Restaurant>;
    order?: Order;
}

const LastOrderCard = ({ date, orderNo, status, restaurant, orderPrice, items, order }: ILastOrderCardProps) => {
    const [open, setOpen] = useState(false);
    const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
    const [clientYonMouseDown, setClientYonMouseDown] = useState(null);
    const { translate } = useDataTranslator();
    const { t } = useTranslation('common');
    const theme = useTheme();
    console.log('restaurant: ', restaurant);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMouseDown = e => {
        setClientXonMouseDown(e.clientX);
        setClientYonMouseDown(e.clientY);
        e.preventDefault();
    };

    return (
        <>
            <Box
                sx={{
                    width: '400px',
                    height: '130px',
                    borderRadius: '10px',
                    // border: `1px solid ${theme.palette.grey.A100}`,
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.10)',

                    display: 'flex',

                    '&:hover': {
                        // boxShadow: { xs: 'none', sm: '0px 20px 40px rgba(85, 141, 202, 0.25)' },
                        // transform: { xs: 'none', sm: 'scale(1.05)' },
                        backgroundColor: theme => `${theme.palette.primary.main}10`,
                    },
                    transition: theme.transitions.create(['transform', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.short,
                    }),
                    backgroundColor: 'white',
                }}
                onClick={e => {
                    e.stopPropagation();
                    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
                        // e.preventDefault()
                    } else {
                        handleOpen();
                    }
                }}
                onMouseDown={handleMouseDown}
            >
                <Box>
                    <img src={order?.partner?.images?.logoUrl} height="130px" width="114px" style={{ borderRadius: '10px' }} />
                </Box>
                <Box ml={2} display="flex" flexDirection="column" justifyContent="space-between" py={1}>
                    <LargeText fontWeight={700}> {restaurant?.name && translate(restaurant?.name).toUpperCase()}</LargeText>
                    <Box display="flex" flexDirection="column">
                        <SmallText>{moment(date.toDate()).format('DD MMMM YYYY, HH:mm')}</SmallText>
                        <Box display="flex" py={0.5}>
                            <img src={locationVector} />
                            <SmallText sx={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '220px', ml: 0.5 }}>
                                {order?.customer?.address?.fullAddress}
                            </SmallText>
                        </Box>
                        <NormalText fontWeight={700}>
                            {order?.total} {t('mkd')}
                        </NormalText>
                    </Box>
                </Box>
                {/* <Box pt={1}>
                    <Divider />
                </Box>
                <Box pb={1} pt={0.5}>
                    <SmallText>
                        {t('date')}: <b>{moment(date.toDate()).format('DD MMMM YYYY')}</b>
                        ,&nbsp;&nbsp;
                        {moment(date.toDate()).format('HH:mm')}
                    </SmallText>
                    <br />
                    <SmallText>{t('price', { count: 1 })}:</SmallText>{' '}
                    <SmallText semibold>
                        {getOrderPrices(order).total} {t('mkd')}
                    </SmallText>
                </Box> */}
            </Box>
            <ModalPopup open={open} handleClose={handleClose}>
                <Box width="100%">
                    <LastOrderDetails
                        key={orderNo}
                        date={date}
                        orderNo={orderNo}
                        status={status}
                        orderPrice={orderPrice}
                        items={items}
                        restaurant={restaurant}
                        order={order}
                    />
                </Box>
            </ModalPopup>
        </>
    );
};
export default LastOrderCard;
