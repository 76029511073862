import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { validationTypes } from 'types/validation';
import { SmallText } from './Typography';

interface IValidationMessageProps {
    type: keyof typeof validationTypes;
    align?: 'center' | 'left' | 'right';
}

const ValidationMessage = ({ type, align = 'left' }: IValidationMessageProps) => {
    const validationInfo = useSelector((state: RootState) => state.validationInfo.data);

    return (
        <>
            {validationInfo[type].hasError && (
                <SmallText style={{ textAlign: align }} color="error">
                    {validationInfo[type].message}
                </SmallText>
            )}
        </>
    );
};

export default ValidationMessage;
