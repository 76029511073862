import { Box, Button, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import React, { useEffect, useMemo, useState } from 'react';
import { stateIsLoaded, stateIsNotReady } from 'services/stateHelpers';
import { useFirestore } from 'hooks/useFirestore';
import { isClientSide } from 'services/utils';
import { Region, Restaurant } from 'klikni-jadi-shared-stuff';
import { DocumentSnapshot, DocumentData } from '@firebase/firestore-types';
import Render from 'components/atoms/Render';
import Skeleton from '@mui/material/Skeleton';
import { Headings, NormalText } from 'components/atoms/Typography';
import LandingPageTopSection from './components/TopSection';
import PropertyCardSkeleton from 'components/molecules/skeletons/PropertyCardSkeleton';
import LastOrders from './components/LastOrders/LastOrders';
import Favorites from './components/Favorites';
import BannerComponent from './components/BannerComponent';
import Recommend from './components/Recommend';
import useTranslation from 'next-translate/useTranslation';
import theme from 'core/theme';
import { forEach, map } from 'lodash';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { useRouting } from 'services/routing';
import Carousel from 'components/specific/Carousel';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { restExecutor } from 'services/rest-executor/executor';
import { _generateToken } from 'services/cart/useCartService/token';
import JoinUs from './components/JoinUs';
import SuggestRestaurant from './components/SuggestRestaurant';
import BecomePartner from './components/BecomePartner';
import { PrimaryButton } from 'components/atoms/Buttons';

// const Favorites = loadable(() => import('./components/Favorites'));
// const LandingPageTopSection = loadable(() => import('./components/TopSection'));
// const LastOrders = loadable(() => import('./components/LastOrders/LastOrders'));
// const BannerComponent = loadable(() => import('./components/BannerComponent'));
// const Recommend = loadable(() => import('./components/Recommend'));
// const SuggestRestaurant = loadable(() => import('./components/SuggestRestaurant'));
// const PropertyCardSkeleton = loadable(() => import('components/molecules/skeletons/PropertyCardSkeleton'));
// const JoinUs = loadable(() => import('./components/JoinUs'));

const visibleOrdersStatus = ['delivered'];

interface LandingPageContainerProps {
    regions: Region[];
}

const LandingPageContainer = ({ regions }: LandingPageContainerProps) => {
    const user = useSelector((state: RootState) => state.currentUser);
    const userOrders = useSelector((state: RootState) => state.userOrders);
    const favoriteRestaurants = useSelector((state: RootState) => state.favoriteRestaurants);
    // const banners = useSelector((state: RootState) => state.banners.data);
    const filterSettings = useSelector((state: RootState) => state.settingsFilters.data);
    const ordersRef = useFirestore('orders');
    const dispatch = useDispatch();
    const recommendedRestaurants = useSelector((state: RootState) => state.recommendedRestaurants.data);
    const settingsRef = useFirestore('settings');
    // const bannersRef = useFirestore('banners');
    const restaurantsRef = useFirestore('restaurants');
    const [clientXonMouseDown, setClientXonMouseDown] = useState(null);
    const [clientYonMouseDown, setClientYonMouseDown] = useState(null);

    const routing = useRouting();

    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();

    const { observer, width } = useResizeObserver();

    useEffect(() => {
        if (isClientSide()) {
            const element = document.getElementById('landing-cuisines-wrapper');
            if (element) {
                observer.observe(element);
                return () => {
                    observer.unobserve(element);
                };
            }
        }
    }, []);

    // useEffect(() => {
    //     import('redux/actions/firestore/banners').then(({ bannersFirestoreActions }) => {
    //         bannersRef.doc('home', bannersFirestoreActions);
    //     });
    // }, []);

    useEffect(() => {
        import('redux/actions/firestore/settings').then(({ settingsFirestoreActions }) => {
            settingsRef.doc('filters', settingsFirestoreActions);
        });
    }, []);

    useEffect(() => {
        import('redux/actions/firestore/restaurants').then(({ recommendedRestaurantsFirestoreActions }) => {
            restaurantsRef.collection(recommendedRestaurantsFirestoreActions, {
                queries: [
                    {
                        attribute: 'sections.isSponsored',
                        operator: '==',
                        value: true,
                    },
                    {
                        attribute: 'status.isActive',
                        operator: '==',
                        value: true,
                    },
                ],
            });
        });
    }, []);

    // useEffect(() => {
    //     import('redux/actions/firestore/orders').then(({ ordersFirestoreActions }) => {
    //         if (user?.data && user?.data.id) {
    //             ordersRef.collection(ordersFirestoreActions, {
    //                 queries: [
    //                     {
    //                         attribute: 'customer.id',
    //                         operator: '==',
    //                         value: user.data.id,
    //                     },
    //                 ],
    //                 sort: { attribute: 'createdAt', order: 'desc' },
    //             });
    //         } else {
    //             dispatch(ordersFirestoreActions.reset());
    //         }
    //     });
    // }, [user.data?.id]);

    useEffect(() => {
        import('redux/actions/firestore/restaurants').then(({ favoriteRestaurantsFirestoreActions }) => {
            if (user?.data) {
                const promises: Promise<DocumentSnapshot<DocumentData>>[] = [];
                dispatch(favoriteRestaurantsFirestoreActions.loading());
                import('config/firebase/index').then(({ db }) => {
                    forEach(user?.data?.favorites, id => {
                        const ref = db.collection('restaurants').doc(id).get();
                        promises.push(ref);
                    });
                    Promise.all(promises).then(favoriteDocs => {
                        dispatch(
                            favoriteRestaurantsFirestoreActions.success(
                                favoriteDocs.map(doc => ({ id: doc.id, ...doc.data() } as Restaurant))
                            )
                        );
                    });
                });
            } else {
                dispatch(favoriteRestaurantsFirestoreActions.reset());
            }
        });
    }, [user.data?.id]);

    const previousOrders = useMemo(() => {
        if (stateIsLoaded(userOrders)) {
            return userOrders.data?.filter(order => visibleOrdersStatus.includes(order.status)) || [];
        } else return [];
    }, [userOrders]);

    const displayFavoriteRestaurants = useMemo(() => {
        if (favoriteRestaurants.data && favoriteRestaurants.data.length > 0) {
            if (previousOrders && previousOrders.length === 0) {
                return favoriteRestaurants.data?.slice(0, cardsCountFull);
            } else {
                return favoriteRestaurants.data?.slice(0, cardsCountSplit);
            }
        }
    }, [favoriteRestaurants, previousOrders]);

    const displayLastOrders = useMemo(() => {
        if (previousOrders && previousOrders.length > 0) {
            if (favoriteRestaurants.data && favoriteRestaurants.data.length === 0) {
                return previousOrders?.slice(0, cardsCountFull);
            } else {
                return previousOrders?.slice(0, cardsCountSplit);
            }
        }
    }, [favoriteRestaurants, previousOrders]);

    let cardsCountSplit = 2;
    let cardsCountFull = 4;

    if (isClientSide()) {
        if (window.innerWidth > 1440) {
            cardsCountSplit = 3;
            cardsCountFull = 6;
        } else {
            cardsCountSplit = 2;
            cardsCountFull = 4;
        }
    }

    const handleCuisineClick = (cuisineId: string) => {
        routing.push({ to: '/listings', queries: { cuisineId: cuisineId } });
    };

    const handleImageMouseDown = e => {
        setClientXonMouseDown(e.clientX);
        setClientYonMouseDown(e.clientY);
        e.preventDefault();
    };

    return (
        <>
            <Box
                // sx={{
                //     height: 'calc(100vh - 80px)',
                // }}
                display="flex"
                flexDirection="column"
            >
                <Box>
                    <LandingPageTopSection regions={regions} />
                </Box>

                <Render when={user.data && user.data.favorites && stateIsNotReady(favoriteRestaurants)}>
                    <Container maxWidth="md">
                        <Box pt={8}>
                            <Headings.H2>
                                <Skeleton width={300} />
                            </Headings.H2>
                            <Box mt={3} display="flex" flexWrap="wrap">
                                <Box mr={5} mb={3}>
                                    <PropertyCardSkeleton />
                                </Box>
                                <Box mr={5} mb={3}>
                                    <PropertyCardSkeleton />
                                </Box>
                                <Box mr={5} mb={3}>
                                    <PropertyCardSkeleton />
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Render>
                {/* <Render when={!!user.data}>
                    <Box
                        px={{ xs: 1, md: 15 }}
                        sx={{ backgroundColor: theme.palette.grey[50], paddingBottom: '20px', paddingTop: '20px' }}
                        id="landing-cuisines-wrapper"
                    >
                        <Headings.H2 sx={{ paddingBottom: '20px', fontSize: 24 }}>{t('cuisine', { count: 2 })}</Headings.H2>
                        <Box
                            sx={{
                                px: {
                                    xs: 3,
                                    md: 0,
                                },
                            }}
                        >
                            <Carousel slidesToShow={width / 130}>
                                {map(filteredCuisines, cuisine => {
                                    return (
                                        <Box>
                                            <Button
                                                sx={{
                                                    width: '110px',
                                                    minWidth: '110px',
                                                    marginRight: '20px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '10px',
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
                                                        // e.preventDefault()
                                                    } else {
                                                        handleCuisineClick(cuisine.id);
                                                    }
                                                }}
                                                onMouseDown={handleImageMouseDown}
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img
                                                        src={cuisine.imageUrl}
                                                        style={{
                                                            width: 50,
                                                            height: 50,
                                                            marginBottom: '10px',
                                                        }}
                                                    />
                                                    <NormalText>{translate(cuisine.name)}</NormalText>
                                                </Box>
                                            </Button>
                                        </Box>
                                    );
                                })}
                            </Carousel>
                        </Box>
                    </Box>
                </Render> */}
            </Box>

            <Render
                when={
                    (previousOrders && previousOrders.length > 0 && stateIsLoaded(user)) ||
                    (user?.data && favoriteRestaurants.data && favoriteRestaurants.data?.length > 0)
                }
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey[50],
                    }}
                    pt={8}
                    display="flex"
                    flexDirection="column"
                >
                    {previousOrders && previousOrders.length > 0 && (
                        <Box>
                            <LastOrders lastOrders={displayLastOrders} allLastOrdersLen={previousOrders.length} />
                        </Box>
                    )}

                    {favoriteRestaurants.data && favoriteRestaurants.data?.length > 0 && (
                        <Box mt={5}>
                            <Favorites
                                restaurants={displayFavoriteRestaurants}
                                allFavoritesLen={favoriteRestaurants.data?.length}
                            />
                        </Box>
                    )}
                </Box>
            </Render>

            {/* {banners && banners.position === 'top' && (
                <Box py={3} mb={4}>
                    <BannerComponent banner={banners} />
                </Box>
            )}

            {banners && banners.position === 'middle' && (
                <Box py={3} mb={1}>
                    <BannerComponent banner={banners} />
                </Box>
            )} */}

            {/* {recommendedRestaurants && user.data && (
                <Box>
                    <Recommend />
                </Box>
            )} */}
            {/* <Box pt={4} className={classes.greyBackground}>
                <Blogs />
            </Box> */}
            <Box
                pt={4}
                sx={{
                    backgroundColor: theme.palette.grey[50],
                }}
            >
                <JoinUs />
            </Box>
            <Box pb={5} pt={10}>
                <SuggestRestaurant />
            </Box>
            <Box pb={15} pt={10}>
                <BecomePartner />
            </Box>
        </>
    );
};

export default LandingPageContainer;
