import { Box, Container } from '@mui/material';
import { Headings } from 'components/atoms/Typography';
import { getDetailsRouterArguments, getDetailsUrl } from 'services/application';
import Link from 'next/link';
import { Restaurant } from 'klikni-jadi-shared-stuff';
import vector from 'assets/svg/view-more.svg';
import { SmallText } from 'components/atoms/Typography';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { isClientSide } from 'services/utils';
import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useDataTranslator } from 'hooks/useDataTranslator';
import PropertyCard from 'containers/listings/components/PropertyCard';
import { useRouter } from 'next/router';
import { useCurrentLocationContext } from 'context/location';
import PartnerCard from 'containers/partners/components/PartnerCard';

// const PropertyCard = loadable(() => import('containers/listings/components/PropertyCard'));

interface FavoritesProps {
    restaurants?: Restaurant[];
    allFavoritesLen?: number;
}

const Favorites = ({ restaurants, allFavoritesLen }: FavoritesProps) => {
    const { observer, width } = useResizeObserver();
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const router = useRouter();
    const { data: location } = useCurrentLocationContext();

    useEffect(() => {
        if (isClientSide()) {
            const element = document.getElementById('favorite-restaurants-container');
            if (element) {
                observer.observe(element);
                return () => {
                    observer.unobserve(element);
                };
            }
        }
    }, []);

    return (
        <>
            <Box width="100%" ml={1}>
                <Container maxWidth="md">
                    <Box pb={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Headings.H3>{t('favorite_restaurants')}</Headings.H3>
                    </Box>
                    <Box id="favorite-restaurants-container" display="flex">
                        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                            <Box display="flex" flexWrap="wrap" width="100%">
                                {restaurants &&
                                    restaurants.map((restaurant, index) => (
                                        <Box position="relative" key={restaurant.id} pr={3}>
                                            <Link
                                                href={getDetailsRouterArguments(restaurant.id, restaurant.name.en)}
                                                as={getDetailsUrl(restaurant.id, restaurant.name.en)}
                                            >
                                                <a>
                                                    <Box className="cursor-pointer" width={300}>
                                                        <PartnerCard key={restaurant.id} partner={restaurant} />
                                                    </Box>
                                                </a>
                                            </Link>
                                        </Box>
                                    ))}
                            </Box>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' }, overflow: 'scroll' }}>
                            {/* <Carousel arrows={false} slidesToShow={width / 250}> */}
                            <Box display="flex" flexWrap="nowrap">
                                {restaurants &&
                                    restaurants.map(restaurant => (
                                        <Box pr={5} position="relative" key={restaurant.id}>
                                            <Link
                                                href={getDetailsRouterArguments(restaurant.id, restaurant.name.en)}
                                                as={getDetailsUrl(restaurant.id, restaurant.name.en)}
                                            >
                                                <a>
                                                    <Box className="cursor-pointer">
                                                        <PropertyCard
                                                            key={restaurant.id}
                                                            image={restaurant.images.thumbnailUrl}
                                                            logo={restaurant.images.logoUrl}
                                                            cuisines={restaurant.cuisineIds}
                                                            name={translate(restaurant.name)}
                                                            view="grid"
                                                            id={restaurant.id}
                                                            property={restaurant}
                                                        />
                                                    </Box>
                                                </a>
                                            </Link>
                                        </Box>
                                    ))}
                            </Box>
                            {/* </Carousel> */}
                            {/* <Grid container spacing={6}>
                            {restaurants &&
                                restaurants.map(restaurant => (
                                    <Grid item key={restaurant.id} style={{ paddingBottom: 0 }} xs={12} sm={6}>
                                        <Link
                                            href={getDetailsRouterArguments(restaurant.id, restaurant.name.en)}
                                            as={getDetailsUrl(restaurant.id, restaurant.name.en)}
                                        >
                                            <a>
                                                <Box className="cursor-pointer" position="relative" width="100%">
                                                    <PropertyCard
                                                        key={restaurant.id}
                                                        image={restaurant.images.thumbnailUrl}
                                                        logo={restaurant.images.logoUrl}
                                                        cuisines={restaurant.cuisineIds}
                                                        name={restaurant.name.en}
                                                        price={restaurant.price}
                                                        rating={restaurant.avgRating}
                                                        view="grid"
                                                        fullWidth
                                                    />
                                                </Box>
                                            </a>
                                        </Link>
                                    </Grid>
                                ))}
                        </Grid> */}
                        </Box>
                    </Box>
                    {allFavoritesLen > restaurants?.length && (
                        <Box textAlign="right" pt={7}>
                            <Link href={{ pathname: '/profile/orders', query: { 'orders-view': 'history' } }}>
                                <a>
                                    <Box pr={9}>
                                        <SmallText color="textPrimary">
                                            {t('seeMore')}{' '}
                                            <img src={vector} alt="vector" style={{ width: '20px', marginLeft: '5px' }}></img>
                                        </SmallText>
                                    </Box>
                                </a>
                            </Link>
                        </Box>
                    )}
                </Container>
            </Box>
        </>
    );
};
export default Favorites;
