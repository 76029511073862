import { ValidationType } from '../../types/validation';
import { useDispatch, useSelector } from 'react-redux';
import { schemaType } from './schemas';
import { resetValidationInfo, setValidationInfo } from 'redux/actions/internal/general/validation';
import { RootState } from 'redux/reducers';

export const useValidator = () => {
    const dispatch = useDispatch();
    const validationInfo = useSelector((state: RootState) => state.validationInfo.data);

    return {
        validate: async (type: ValidationType, obj) => {
            try {
                let valid = await schemaType[type].validate(obj, { abortEarly: true });
                dispatch(resetValidationInfo(type));
                return valid;
            } catch (err) {
                dispatch(
                    setValidationInfo(
                        {
                            hasError: true,
                            message: err.errors[0],
                            errorType: err.path,
                        },
                        type
                    )
                );
                return false;
            }
        },
        resetValidation: (...types: ValidationType[]) => {
            dispatch(resetValidationInfo(...types));
        },
        hasError: (type: ValidationType, errorType: string) =>
            validationInfo[type].errorType === errorType,
    };
};

export const useErrorFocus = (focusType: string, ...types: ValidationType[]) => {
    const validationInfo = useSelector((state: RootState) => state.validationInfo.data);
    return types.map(t => validationInfo[t].errorType).includes(focusType);
};
